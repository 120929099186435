.inventory-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.locationRow {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  padding-bottom: 1rem;
 
}
.comparedToRow {
  font-size: 1.4rem;
  margin-bottom: 0.1rem;
  letter-spacing: 0.01em;
  font-weight: 200;
}
.secondaryLocationRow {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  height: 26px;
}
