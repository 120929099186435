@import "./src/sass/index.scss";

.services-merch-detail-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;

  .dropdown-container {
    margin-top: 15px;
    margin-bottom: 7px;
    justify-content: flex-end;
    display: inline-block;
    width: 61%;
    align-self: center;

    .dropdown-column {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-basis: 60%;
      justify-content: center;
      align-items: center;
      margin: 5px 0px;

      .dropdown-row {
        width: 100%;
        font-weight: 100;
        font-size: 1.8rem;

        @include respond("tab-port") {
          font-size: 1.8rem;
        }

        @include respond("phone") {
          font-size: 1.4rem;
        }

        @include respond("desktop") {
          font-size: 2rem;
        }
      }

      .dropdown-row>div {
        border-color: #353535 !important;
        border-radius: 16px !important;
        min-height: 33px !important;
        background-color: #ffffff !important;

        .css-qc6sy-singleValue {
          font-size: 1.8rem !important;
          padding-top: 1px;
          font-weight: 100;
          color: #1D1B20 !important;
        }

        .css-1okebmr-indicatorSeparator {
          display: none !important;
        }

        .css-1gtu0rj-indicatorContainer,
        .css-tlfecz-indicatorContainer {
          padding: 0 !important;
        }

        svg {
          display: inline-block;
          fill: #1D1B20 !important;
          border-left: 0.7rem solid transparent;
          border-right: 0.7rem solid transparent;
          border-top: 0.7rem solid #000000;
          width: 0;
          height: 0;
          margin-right: 9px;
        }
      }
    }

    .class-level-dropdown {
      margin-top: 15px;
    }
  }

  .table-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    text-align: center;
    height: calc(100% - 7.5rem);

    .border-row {
      width: 100%;
      height: 1px;
      margin-top: 0.5rem;
      background-color: #bfb2b2;
    }

    .top-border-row {
      background-color: #0066ff;
      margin-bottom: 0.5rem;
    }

    .bottom-border-row {
      background-color: #B3B3B3;
      margin-bottom: 1rem;
    }

    .services-merch-title {
      padding-right: 5px;
      padding-left: 5px;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      flex-shrink: 0;
      font-weight: 600;
      font-size: 1.8rem;
      color: #444444;

      @include respond("tab-port") {
        font-size: 2rem;
      }

      @include respond("phone") {
        font-size: 1.5rem;
      }
    }

    .services-merch-row-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .services-merch-Subtitle {
        padding-right: 5px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-shrink: 0;
        font-size: 1.7rem;
        font-weight: bolder;
        text-align: start;
        width: 100%;
        color: #202020;

        @include respond("tab-port") {
          font-size: 1.8rem;
        }

        @include respond("phone") {
          font-size: 1.4rem;
        }
      }
    }

    .services-merch-sales-data {
      display: flex;
      justify-content: right;

      .services-merch-sales-datavalues {
        width: 32%;
        text-align: right;
        padding-right: 2%;
       
        @include respond("tab-port") {
          font-size: 1.8rem;
           padding-right: 3%;
        }

        @include respond("phone") {
          font-size: 1.3rem;
           padding-right: 3%;
        }
        @include respond("desktop") {
           padding-right: 3%;
        }

        .services-merch-sales-value {
          font-size: 1.8rem;

          @include respond("tab-port") {
            font-size: 1.8rem;
          }

          @include respond("phone") {
            font-size: 1.3rem;
          }
        }
      }
    }

    .services-merch-sales-container {
      flex-shrink: 0;


    }

    .services-merch-sku-container {
      flex-shrink: 0;
      text-align: center;

      .services-merch-sku-value {
        font-size: 1.6rem;

        @include respond("tab-port") {
          font-size: 1.7rem;
        }

        @include respond("phone") {
          font-size: 1.3rem;
        }

        font-weight: bolder;
      }

      .services-merch-sku-label {
        font-size: 1.5rem;

        @include respond("tab-port") {
          font-size: 1.6rem;
        }

        @include respond("phone") {
          font-size: 1.2rem;
        }

        color: #555555;
      }
    }

    .table-content {
      padding-bottom: 1rem;
      overflow: auto;

      .sort-container {
        position: relative;

        .sort-box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 1rem;
          margin-top: 0.5rem;
          margin-bottom: -0.9rem;
          font-weight: 850;
          font-size: 2rem;

          @include respond("phone") {
            font-size: 1.6rem;
          }
        }

        .sort-menu-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          right: 0;
          margin-right: 1.5rem;
          border: 1px solid rgba(85, 85, 85, 1);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 5px;
          background-color: white;

          .menu-item {
            display: flex;
            justify-content: space-between;
            min-width: 20rem;

            &:not(:last-child) {
              border-bottom: 1px solid rgba(85, 85, 85, 1);
            }

            &.ascending-menu-item {
              border-top: 2px solid rgba(85, 85, 85, 1);
            }

            font-weight: 850;
            font-size: 1.8rem;

            @include respond("phone") {
              font-size: 1.6rem;
            }

            &.apply-button {
              font-size: 1.7rem;
              justify-content: center;
            }

            &.sort-by-header {
              font-size: 1.6rem;
              justify-content: center;
              color: #818080;
            }
          }
        }
      }
    }

    .table-content-nodata {
      overflow: hidden;
    }

    .sku-table-container {
      height: calc(100% - 50px);

      @include respond("phone") {
        height: calc(100% - 60px);
      }

      text-align: center;
      overflow: auto;
    }

    .class-sku-table-container {
      height: 100%;
      padding-bottom: 160px;

      @include respond("tab-port") {
        padding-bottom: 145px;
      }

      @include respond("phone") {
        padding-bottom: 130px;
      }
    }
  }

  .services-merch-label-container {
    display: flex;
    justify-content: right;

    .services-merch-sales-label {
      font-size: 1.8rem;
      color: #555555;
      width: 32%;
      text-align: right;
      padding-right: 3%;
      font-weight: 600;

      @include respond("tab-port") {
        font-size: 1.8rem;
      }

      @include respond("phone") {
        font-size: 1.3rem;
      }
    }
  }

  .services-merch-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    height: 50%;
  }
}

.services-merch-noData-card {
  display: flex;
  flex-direction: column;
  height: 400px;
  background: white;
  text-align: center;

  .no-data-row {
    .label {
      justify-content: center;
      font-weight: 900;
      font-size: 2rem;
      color: #010101;
      letter-spacing: 0.02em;
      padding-top: 4rem;
    }
  }
}