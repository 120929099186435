@import "./src/sass/index.scss";

.modal-container {
    position: absolute;
    right: 35px;
    margin-top: 33px;

    .modal-open-btn {
        float: right;
        cursor: pointer;
        border: none;
    }

    .modal-open-btn-img {
        width: 32px;
        height: 35px;

        @media only screen and (max-width: 768px) {
            width: 22px;
            height: 25px;
        }
    }
}

.modal-header-container {
    display: flex;
    align-items: flex-end;

    .modal-close-btn-icon {
        position: relative;
        width: 19px;
        height: 19px;
        cursor: pointer;
        bottom: 6px;
        align-self: normal;
    }

    .modal-heading {
        font-family: "Franklin Gothic Pro";
        color: #000000;
        font-size: 20px;
        font-weight: bolder;
        text-align: center;
        text-transform: capitalize;
        width: 100%;
        float: right;
        line-height: 20px;
    }
}

.modal-content {
    padding: 5px;
    height: 92%;

    .modal-content-summaryData-title {
        .modal-summaryData-title {
            font-family: "Franklin Gothic Pro";
            color: #232323;
            font-size: 17px;
            font-weight: bolder;
            text-transform: lowercase;
            margin-left: 5px;
        }

        .modal-summaryData-title::first-letter,
        .modal-summaryData-title::first-line {
            text-transform: capitalize;
        }
    }

    .modal-content-summaryData-container {
        height: 82%;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .expanded-sales-summary-content-container {
        height: 85.33%;

        @media only screen and (min-width: 1220px) and (max-width: 1280px) {
            height: 85%;
        }

        @media only screen and (min-width: 908px) and (max-width: 912px) {
            height: 96%;
        }

        @include respond("tab-port") {
            height: 90%;
        }

        @media only screen and (max-width: 420px) {
            height: 93%;
        }

        @media only screen and (max-width: 389px) {
            height: 87.33%;
        }

        @media only screen and (max-width: 280px) {
            height: 82%;
        }
    }

    .expanded-service-summary-content-container {
        height: 81.33%;

        @media only screen and (min-width: 1220px) and (max-width: 1280px) {
            height: 80%;
        }

        @media only screen and (min-width: 908px) and (max-width: 912px) {
            height: 94%;
        }

        @include respond("tab-port") {
            height: 86%;
        }

        @media only screen and (max-width: 420px) {
            height: 90.33%;
        }

        @media only screen and (max-width: 389px) {
            height: 83.33%;
        }

        @media only screen and (max-width: 280px) {
            height: 84%;
        }
    }

    .expanded-omni-channel-summary-content-container {
        height: 81.33%;

        @media only screen and (min-width: 1220px) and (max-width: 1280px) {
            height: 80%;
        }

        @media only screen and (min-width: 908px) and (max-width: 912px) {
            height: 94%;
        }

        @include respond("tab-port") {
            height: 86%;
        }

        @media only screen and (max-width: 420px) {
            height: 90.33%;
        }

        @media only screen and (max-width: 389px) {
            height: 83.33%;
        }

        @media only screen and (max-width: 280px) {
            height: 84%;
        }
    }

    .expanded-labor-summary-content-container {
        height: 85.33%;

        @media only screen and (min-width: 1220px) and (max-width: 1280px) {
            height: 85%;
        }

        @media only screen and (min-width: 908px) and (max-width: 912px) {
            height: 96%;
        }

        @include respond("tab-port") {
            height: 90%;
        }

        @media only screen and (max-width: 420px) {
            height: 93%;
        }

        @media only screen and (max-width: 389px) {
            height: 87.33%;
        }

        @media only screen and (max-width: 280px) {
            height: 80%;
        }
    }

    .modal-content-data-row,
    .data-row {
        display: flex;
    }

    .MuiSvgIcon-root {
        fill: #0000cd;
        font-size: 1.8rem !important;
        position: relative;
        bottom: 2px;
        cursor: pointer;
    }

    .modal-content-data-row {
        border-bottom: 2.5px solid #587bd4;
        padding-bottom: 10px;
        justify-content: space-around;

        .labor-data-item-container,
        .summary-data-item-container {
            flex-basis: 25%;
        }

        .summary-data-item-container {
            flex-basis: 33.33%;
        }

        .labor-data-title,
        .summary-data-title {
            text-align: center;
            font-size: 15px;
            color: #444444;
            overflow: hidden;
            padding-top: 5px;
            line-height: 1.2;

            @media only screen and (max-width: 280px) {
                font-size: 11px;
            }
        }

        .labor-data,
        .summary-data {
            font-size: 16px;
            text-align: center;
            word-break: break-all;
            font-weight: bold;
            color: #232323;
            overflow: hidden;
            align-self: center;
            padding-top: 5px;

            @media only screen and (max-width: 280px) {
                font-size: 11px;
            }
        }
    }

    .expanded-service-summary-top-row-container {
        :nth-child(4) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            row-gap: 10px;
        }

        :nth-child(-n + 3) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .summary-data-title {
                white-space: pre-line;
            }
        }
    }

    .expanded-omni-channel-summary-top-row-container {
        :nth-child(4) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            row-gap: 10px;
        }

        :nth-child(-n + 3) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .summary-data-title {
                white-space: pre-line;
            }
        }
    }

    .data-row {
        .labor-data-item-container,
        .summary-data-item-container {
            flex-basis: 50%;
        }

        .labor-data-title,
        .summary-data-title {
            text-align: center;
            font-size: 1.4rem;

            @include respond("phone") {
                font-size: 1.2rem;
            }

            color: #555555;
            overflow: hidden;
            display: none;
        }

        .labor-data,
        .summary-data {
            font-size: 1.6rem;
            text-align: center;
            word-break: break-all;

            @include respond("phone") {
                font-size: 1.4rem;
            }

            font-weight: 1000;
            color: #2d2d2d;
            overflow: hidden;
            align-self: center;
        }
    }

    .border-row {
        background-color: #7b68ee;
    }
}

.modal-body-container {
    width: 444px;
    border: 2px solid #fff;
    height: calc(100% - 64px);
    padding: 10px;
    position: absolute;
    box-shadow:
        0px 11px 15px -7px rgb(0 0 0 / 20%),
        0px 24px 38px 3px rgb(0 0 0 / 14%),
        0px 9px 46px 8px rgb(0 0 0 / 12%);
    background-color: #fff;
    border-radius: 4px;
    left: 35%;
    top: 5%;

    @media only screen and (max-width: 920px) {
        left: 23%;
        top: 8%;
        height: calc(100% - 200px);
    }

    @media only screen and (max-width: 680px) {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        left: 0;
        top: 0;
    }
}

.table-container-wrapper {
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
        display: none;
        @media screen {
            @media (min-width: 768px) {
                display: block;
                width: 5px;
                overflow: auto;
                height: 8px;
                padding: 1em;
                margin: 1em auto;
                
            }
        }
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        display: none;
        @media screen {
            @media (min-width: 768px) {
                display: block;
                width: 5px;
                overflow: auto;
                height: 8px;
                padding: 1em;
                margin: 1em auto;
               
            }
        }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        display: none;
        @media screen {
            @media (min-width: 768px) {
                display: block;
                width: 5px;
                overflow: auto;
                height: 8px;
                padding: 1em;
                margin: 1em auto;
               
            }
        }
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
        display: none;
        @media screen {
            @media (min-width: 768px) {
                display: block;
                width: 5px;
                overflow: auto;
                height: 8px;
                padding: 1em;
                margin: 1em auto;
            }
        }
    }
    table {
        thead {
            tr {
                th {
                    border-bottom: 2.5px solid #587bd4 !important;
                    background-color: #ffffff !important;
                }
            }
        }
    }
}
