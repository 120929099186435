@import "./src/sass/index.scss";
.efficiency-container {
  padding: 2px;
  height: 100%;
  width: 99%;
  margin-top: 10px;
  margin-bottom: 10px;
	overflow: auto;

  .efficiency-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
  }

  .efficencySplitScreen {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    row-gap:10px;
  }
  .efficencyTopPane {
    height: 58%;
    width: 98%;
    .efficencyInnerTopPane {
      display: flex;
      flex-direction: column;
      height: 100%;     
      .efficencyTitleTopPane {
        height: 10%;
        align-self: center;
        .efficency-title-row-top {
          font-weight: bolder;
          color: rgba(0, 0, 0, 1);
          letter-spacing: 0.025em;

          @include respond("tab-port") {
            font-size: 2.5rem;
          }
          @include respond("phone") {
            font-size: 1.5rem;
          }
          @include respond("desktop") {
            font-size: 5rem;
          }
        }
      }
      .efficencyContentTopPane {
        height: 100%;
        width: 100%;
        margin: 5px;
        justify-content: center;
      }
    }
  }
  .dividerLine {
    background-color: #979797;
    width: 100%;
    margin-top: 25px;
  }
  .efficencyBottomPane {
    height: 48%;
    margin-top: 4px;

    .efficencyInnerBottomPane {
      display: flex;
      flex-direction: column;
      height: 100%;
      .efficencyTitleBottomPane {
        height: 10%;
        align-self: center;
        .efficency-title-row-bottom {
          font-weight: bolder;
          color: rgba(0, 0, 0, 1);
          letter-spacing: 0.025em;

          @include respond("tab-port") {
            font-size: 2.5rem;
          }
          @include respond("phone") {
            font-size: 1.5rem;
          }
          @include respond("desktop") {
            font-size: 5rem;
          }
        }
      }
      .efficencyContentBottomPane {
        height: 100%;
        margin: 5px;
        justify-content: center;
        canvas {
          @media screen {
            @media screen and (max-width: 767px) {
              min-height: 150px;
            }
          }
        }
      }
    }
  }
}
