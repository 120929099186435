@import "sass/index";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  @include respond(phone) {
  }
}

@font-face {
  font-family: "Franklin Gothic Pro";
  src: url("./fonts/franklin_gothic_pro-book-webfont.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Franklin Gothic Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .snack {
    font-size: 12px;
  }
  .snackbar-button{
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 102, 255, 1);
    background-color: #FFFFFF;
  }
}

code {
  font-family: "Franklin Gothic Pro";
}