.tab-manager {
  padding: 1.5rem;
  margin-top: 1rem;
  height: 93%;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 280px) {
    padding: 1rem;
    padding-top: 1.5rem;
  }

  .tab-headers {
    display: flex;
    flex-direction: row;

    .tab-header {
      padding: 0.5rem 1rem;
      font-size: 1.8rem;
      font-weight: 600;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      color: rgba(68, 68, 68, 1);
      cursor: pointer;

      @media only screen and (max-width: 280px) {
        padding: 0.2rem 0.5rem;
        line-height: 23px;
      }
    }
  }

  .tab-content {
    background-color: white;
    display: flex;
    flex-grow: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: auto;
    flex-direction: column;
  }

  .trend-tab-content {
    background-color: white;
    display: flex;
    flex-grow: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    flex-direction: column;
  }

  .active-tab {
    background-color: white;
    font-weight: bold !important;
    color: rgba(35, 35, 35, 1) !important;
  }

  .active-tab-indicator {
    width: 100%;
    height: 3px;
    margin-bottom: 0.5rem;
  }
}