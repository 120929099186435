  .footer-container {
    background-color: rgba(0, 102, 255, 1);
    color:white;
    text-align: "center";
    padding: 8px;
    height: 7%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text{
      font-size: 18px;
      font-weight: 600;
    }
    .btnLeft{
      background-color: white !important;
      padding: 1px;
      margin-left: 35px;
    }
    .btnRight{
        background-color: white !important;
        padding: 1px;
        margin-right: 35px;
    }
  }
