@import "./src/sass/index.scss";

.services-trend-container {
	height: 100%;
	width: 99%;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 5px;
	overflow: auto;
	
	@include respond("tab-port") {
		width: 98%;
	}

	@include respond("phone") {
		width: 98%;
	}

	.services-detail-loader {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;
		width: 100%;
	}

	.services-trend-titles {
		font-family: Franklin Gothic Pro !important;
		font-size: 1.8rem !important;
		color: #444444 !important;
		text-align: center !important;
		margin-left: 8px !important;
		font-weight: 600 !important;

		@include respond("tab-port") {
			font-size: 1.8rem !important;
		}

		@include respond("phone") {
			font-size: 1.5rem !important;
		}
	}

	.services-bottomPane {
		height: 100%;
		width: 98%;

		.innerBottomPane {
			display: flex;
			flex-direction: column;
			height: 100%;

			.titleBottomPane {
				height: 10%;
				.title-row-bottom {
					font-weight: bolder;
					color: rgba(0, 0, 0, 1);

					@include respond("tab-port") {
						font-size: 2.5rem;
					}
					@include respond("phone") {
						font-size: 1.5rem;
					}
					@include respond("desktop") {
						font-size: 5rem;
					}
				}
			}
			.panel-wrapper {
				@media screen and (max-width: 920px) {
					overflow-x: scroll;
					overflow-y: hidden;
					height: 90%;
				}
				@include respond("tab-port") {
					overflow-x: scroll;
					overflow-y: hidden;
					height: 90%;
				}
				.contentBottomPane {
					margin: 5px;
					justify-content: center;
					min-width: 762px !important;
					@media screen and (max-width: 920px) {
						height: 90%;
					}
					@include respond("tab-port") {
						height: 90%;
					}
				}
			}
		}
	}

	.services-splitScreen {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		justify-content: space-between;
	}
	.services-topPane {
		height: 100%;
		width: 98%;
		.innerTopPane {
			display: flex;
			flex-direction: column;
			height: 100%;

			.titleTopPane {
				height: 10%;
				.title-row-top {
					font-weight: bolder;
					color: rgba(0, 0, 0, 1);

					@include respond("tab-port") {
						font-size: 2.5rem;
					}
					@include respond("phone") {
						font-size: 1.5rem;
					}
					@include respond("desktop") {
						font-size: 5rem;
					}
				}
			}
			.panel-wrapper {
				@media screen and (max-width: 920px) {
					overflow-x: scroll;
					overflow-y: hidden;
					height: 90%;
				}
				@include respond("tab-port") {
					overflow-x: scroll;
					overflow-y: hidden;
					height: 90%;
				}
				.contentTopPane {
					margin: 5px;
					justify-content: center;
					min-width: 762px !important;
					@media screen and (max-width: 920px) {
						height: 90%;
					}
					@include respond("tab-port") {
						height: 90%;
					}
				}
			}
		}
	}

	.services-divider {
		border: 1px solid #dfdfdf;
		margin-top: 15px !important;
		margin-bottom: 13px !important;
		flex-shrink: 0;
		margin-left: 5px;
		margin-right: 5px;
	}
}