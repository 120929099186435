.sales-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.locationRow {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.01em;
}
