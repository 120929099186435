.locationRow {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
    font-family: Franklin Gothic Pro !important;
    letter-spacing: 0.01em;
    margin-bottom: 0.8rem;
  }
  
  .comparedToRow {
    font-size: 1.4rem;
    margin-bottom: 0.1rem;
    letter-spacing: 0.01em;
    font-weight: 200;
    font-family: Franklin Gothic Pro !important;
  }
  .secondaryLocationRow {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    height: 26px;
    font-family: Franklin Gothic Pro !important;
  }
  