@import "./src/sass/index.scss";
.summary-card {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
  min-height: 15rem;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  @include respond("tab-port") {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    
  }
  @include respond("phone") {
    min-height: 13rem;
  }
  .title-row {
    font-weight: 900;
    font-size: 1.8rem;
    margin-left: 1.8rem;
    @include respond("phone") {
      font-size: 1.4rem;
      margin-left: 1.4rem;
    }
    color: "#005FED" !important;
    margin-bottom: 0.5rem;
  }
  .data-row {
    flex-direction: row;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 0.5rem;
    .summary-data-item-container {
      flex-basis: 50%;
      display: flex;
      margin-right: 1rem;
      overflow: hidden;
      flex-direction: column;
      .summary-data {
        display: flex;
        font-size: 1.8rem;
        text-align: center;
        word-break: break-all;
        @include respond("phone") {
          font-size: 1.4rem;
        }
        font-weight: 1000;
        overflow: hidden;
        align-self: center;
      }
      .summary-data-title {
        display: flex;
        align-self: center;
        font-size: 1.6rem;
        @include respond("phone") {
          font-size: 1.2rem;
        }
        color: rgba(106, 106, 106, 1);
        overflow: hidden;
        text-align: center;
      }
    }
  }
}
