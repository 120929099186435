@import "./src/sass/index.scss";
.summary-detail-tab {
  overflow: hidden;
  .bold {
    font-weight: bold;
  }
  height: 100%;
  .summary-detail-table-container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    height: calc(100% - 30px);
    @include respond("phone") {
      height: calc(100% - 30px);
    }
    overflow: auto;
    text-align: center;
    .header-text {
      text-align: center;
      font-weight: 850;
      font-size: 1.6rem;
      color: rgba(85, 85, 85, 1);
    }
    .data-text {
      font-size: 1.4rem;
      @include respond("tab-port") {
        font-size: 2rem;
      }
      @include respond("phone") {
        font-size: 1.2rem;
      }
      @include respond("desktop") {
        font-size: 5rem;
      }
      font-weight: 500;
      color: rgba(32, 32, 32, 1);
      &.first-column {
        text-align: start;
        font-weight: 850;
      }
      &.first-row {
        font-weight: 900;
      }
    }
    .border-right {
      border-right: 2px;
       border-color: lightgrey;
      border-right-style: solid;
    }
    .border-row {
      width: 100%;
      height: 2px;
      background-color: lightgray;
   
    }
    .divider-border{
      width: 100%;
      height: 1px;
      background-color: lightgray;
    }
    .row-padding {
      padding: 5px;
    }
    .title-padding {
      padding: 5px;
    }
    .table-content {
      overflow: auto;
      height: calc(100% - 54px);
    }
  }
  .summary-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }
}
