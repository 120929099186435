@import "./src/sass/index.scss";
.services-detail-tab {
  overflow: hidden;
  .bold {
    font-weight: bold;
  }
  height: 100%;
  .summary-row {
    display: flex;
    flex-direction: row;
    height: 300px;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    @include respond("phone") {
      height: 150px;
    }
    .summary-row-header-container {
      display: flex;
      flex-basis: 4%;
      flex-direction: column;
      align-items: center;
      overflow: visible;
      .total-services-label {
        .label {
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          font-size: 12px;
          text-align: center;
          letter-spacing: 0.02em;
        }
      }
      .total-services-value {
        .value {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: rgba(35, 35, 35, 1);
          letter-spacing: 0.02em;
        }
      }
    }
    .summary-row-meter-container {
      display: flex;
      justify-content: center;
      flex-basis: 100%;
      overflow: hidden;
    }
    .meter-value-text {
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      line-height: 2rem;
      text-align: center;
      color: rgba(0, 0, 0, 1);
      font-size: 20px;
      font-weight: 900;
      padding-top: 5.5rem;
    }
    .text {
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      font-size: 20px;
      text-align: center;
      letter-spacing: 0.01em;
    }
  }
  .services-detail-table-container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    height: 100%;
    overflow: auto;
    text-align: center;
    .header-text {
      font-size: 1.6rem;
      color: #444444;
      font-weight: 600;
      padding: 0% 0% 0% 0.8%;
      @include respond("tab-port") {
        font-size: 2rem;
        font-weight: 600;
        padding: 0% 0% 0% 1%;
      }
      @include respond("phone") {
        font-size: 1.4rem;
        font-weight: 600;
        padding: 0% 0% 0% 2%;
      }
      @include respond("desktop") {
        font-size: 5rem;
        font-weight: 600;
        padding: 0% 0% 0% 2%;
      }
    }
    .second {
      margin-top: 0.8rem;
    }
    
    .data-text-first {
      width: 70%;
    }
    .data-text-second {
      width: 30%;
      text-align: right;
      overflow-wrap: break-word;
    }
    .data-text {
      color: #444444;
      &.first-column {
        text-align: start;
        font-weight: 850;
        width: 70%;
      }
      &.first-row {
        font-weight: 950;
        @include respond("tab-port") {
          font-size: 2rem;
        }
        @include respond("phone") {
          font-size: 1.2rem;
        }
        @include respond("desktop") {
          font-size: 5rem;
        }
      }
    }
    .value-row {
      color: #444444;
      line-height: 2.8rem;
      @include respond("tab-port") {
        font-size: 2rem;
        line-height: 3.2rem;
      }
      @include respond("phone") {
        font-size: 1.2rem;
        line-height: 2.6rem;
      }
      @include respond("desktop") {
        font-size: 5rem;
        line-height: 2.6rem;
      }
    }
    .border-row {
      width: 100%;
      height: 1px;
      margin-top: 3px;
      margin-bottom: 3px;
      background-color: #DFDFDF
    }
    .dark{
      background-color: #7BA8ED;
    }
    .table-content {
      overflow: auto;
      height: calc(100% - 2rem);
      @media only screen and (max-width: 280px) {
        height: calc(100% - 2rem);
      }
    }
  }
  .services-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }
  .first {
    text-align: left;
    padding-left: 5px;
  }
  .second {
    text-align: right;
    padding-right: 5px;
  }
  .third {
    text-align: right;
    padding-right: 5px;
  }
  .py-20 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}
}
