@import "./src/sass/index.scss";
.inventory-detail-tab {
  overflow: hidden;
  .bold {
    font-weight: bold;
  }
  height: 100%;
  .inventory-detail-table-container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    height: 98%;
    overflow: auto;
    text-align: center;
    .border-row {
      width: 100%;
      height: 1px;
      margin-top: 10px;
      margin-bottom: 8px;
      background-color: rgb(85 85 85 / 48%);
    }
    .top-border-row {
      background-color: #0066ff;
    }
    .table-content {
      overflow: auto;
      height: calc(100% - 44px);
      @include respond("phone") {
        height: calc(100% - 4px);
      }
    }
  }
  .data-row-container {
    display: block;
  }
  .inventory-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }
  .driver-title {
    padding-left: 5px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex-shrink: 0;
    font-weight: 850;
    font-size: 1.8rem;
    @include respond("tab-port") {
      font-size: 2rem;
    }
    @include respond("phone") {
      font-size: 1.6rem;
    }
  }
  .driver-sales-container {
    flex-shrink: 0;
    .driver-sales-value {
      font-size: 1.8rem;
      @include respond("tab-port") {
        font-size: 1.8rem;
      }
      @include respond("phone") {
        font-size: 1.4rem;
      }
      font-weight: bolder;
    }
    .driver-sales-label {
      font-size: 1.8rem;
      @include respond("tab-port") {
        font-size: 1.8rem;
      }
      @include respond("phone") {
        font-size: 1.4rem;
      }
      color: #555555;
    }
  }
}

.longsku-driver-title{
    display: flex;
    justify-content: flex-start;
    padding-right: 5px;
    padding-left:1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 850;
    font-size: 1.6rem;
    text-align: left;
  
    @include respond("tab-port") {
      font-size: 1.8rem;
    }
  
    @include respond("phone") {
      font-size: 1.4rem;
    }
}

.longsku-container{
    display: flex;
    justify-content: space-between;
    margin-left: 5rem;
    margin-right: 7rem;
}

.longsku-bottom-border{
  width: 100%;
  height: 1px;
  margin-top: 7px;
  background-color: #bfb2b2;
}

.longsku-data{
  overflow: auto;
  height:calc(100% - 4rem);
  .longsku-row-container{
    padding-bottom: 1rem;
  }
}

.generic-sort-container{
  position:relative;
}

.generic-sort-closed{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: -0.9rem;
  font-weight: 850;
  font-size: 2rem;
  @include respond("phone") {
    font-size: 1.6rem;
  }
}