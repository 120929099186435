.imp-img {
  width: 70%;
  height: 70%;
}
.locationIcon {
    background-color: transparent;
  z-index: 1;
  position: absolute !important;
  right: 4px;
  flex-direction: column;

  .MuiIconButton-label {
    background-color: transparent;
    position: relative;
    z-index: -1;
  }
}
.closeButton {
  background-color: transparent;
  z-index: 1;
  position: absolute !important;
  top: -4px;
  left: 0.8px;
  flex-direction: column;

  .MuiIconButton-label {
    background-color: transparent;
    position: relative;
    z-index: -1;
  }
}
.userProfile {
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 500px;
  .paper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding-top: 2rem;

    .user-Title {
      font-size: "50px";
      color: #353535;
      text-align: center;
      letter-spacing: 0.02em;
      font-weight: lighter;
      text-align: center;
    }
    .avatar-container {
      width: 100%;
      height: auto;
      flex: 1;
      flex-direction: row;
      display: flex;
      justify-content: space-around;
      margin-top: 5px;
      margin-left: 10rem;
      margin-right: 10rem;

      .avatar-div-style {
        width: 40%;
        align-content: center;
      }
      .divider-div-style {
        width: 30%;
        margin-top: 27%;
      }
    }

    .avatar-outline {
      background-color: #dfdfdf;
      width: 100px;
      height: 100px;
      border: 2px solid #636363;
      margin-left: auto;
      margin-right: auto;
    }
    .avatar-initial {
      font-weight: bolder;
      font-size: 60px;
      color: #353535;
    }
    .divider-Style {
      color: #c7c7c7;
      width: 100%;
      height: 3px;
    }
    .user-id {
      position: absolute;
      height: 24px;
      width: 239px;
      font-size: 40px;
      color: rgba(89, 89, 89, 1);
      text-align: center;
    }

    .avatar-icon {
      padding: 200px;
    }
    .avatar-box {
      width: auto;
      height: auto;
      padding: 5px;
      size: 20px;
      margin: 10px;

      .avatar-img {
        width: 100%;
        height: 100%;
      }
    }
    .user-details {
      display: flex;
      flex-direction: column;

      .userdetails-id {
        position: absolute;
        height: 24px;
        width: 239px;
        font-size: 40px;
        color: rgba(89, 89, 89, 1);
        text-align: center;
      }
      .empId {
        font-size: 33px;
        color: "#595959";
        text-align: center;
        padding: 1px;
        font-weight: bold;
      }
      .mailid {      
         padding-top: 20;    
        font-size: 29px;
        color: #353535;
        letter-spacing: 0.01em;
      }
      .phone {
        font-size: 33px;
        color: #595959;
        letter-spacing: 0.01em;
        padding-bottom: 20;
      }
    }

    .button-text {
      height: 30px;
      width: 118px;
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      letter-spacing: -0.01em;
      line-height: 30px;
    }
    .network-error {
      color: red;
      border-radius: 10;
    }
  }
}
.br {
  border-radius: 8px;
}
.w80 {
  width: 80%;
}

.profilePic {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}
.comment {
  height: 10px;
  background: #777;
  margin-top: 20px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
