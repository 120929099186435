.blue-dropdown {
    .css-26l3qy-menu {
        margin-left: 5px !important;
    }

    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border-radius: 16px !important;
        background-color: #1e57c6 !important;
        border-color: #7497d6 !important;
        width: 20rem !important;

        .css-319lph-ValueContainer {
            padding: 4px 0px 2px 11px;

            .css-qc6sy-singleValue {
                color: #e9e0e0 !important;
            }
        }

        .css-1d8n9bt {
            .css-qc6sy-singleValue {
                color: #e0e0e9 !important;
            }
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer,
        .css-1hb7zxy-IndicatorsContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                display: inline-block;
                fill: #dfdfdf !important;
                border-left: 0.7rem solid transparent;
                border-right: 0.7rem solid transparent;
                border-top: 0.7rem solid #dfdfdf;
                width: 0;
                height: 0;
                margin-right: 4px;
            }
        }
    }
}