@import "./src/sass/index.scss";

.expanded-sales-summary {
  display: flex;
  flex-direction: column;
  .bold {
    font-weight: bold;
  }
  .expanded-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 900;
    font-size: 1.6rem;
    @include respond("phone") {
      font-size: 1.4rem;
    }
    color: "#005FED" !important;
    margin-bottom: 0.5rem;
  }

  .add-remove-icon {
    margin: 0px 5px 0px 5px;
    cursor: pointer;
    img {
      font-size: 1.6rem;
      @include respond("phone") {
        font-size: 1.4rem;
      }
    }
    img[alt="remove icon"] {
      margin: 0px 0px 3px 0px;
    }
  }

  .sales-container {
    margin-left: 1.6rem;
    .bold {
      font-weight: bold;
    }
    @include respond("phone") {
      margin-left: 1.4rem;
    }
    .sales-title {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: bold;
      color: #232323;
      @include respond("phone") {
        font-size: 16px;
      }
      margin-bottom: 1.5px;
    }
    .sales-row-container {
      display: flex;
      flex-direction: column;
    }
    .data-row {
      justify-content: flex-start;
    }
    .summary-data-item-container {
      flex-basis: 33.33333%;
    }
  }
  .border-row {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    background-color: #7f97d4;
  }
  .circular-progress {
    position: absolute;
    top: 30%;
    left: 40%;
    color: #6060f9;
  }

  .expanded-store {
    background-color: #FBFDFF;
    padding-top: 10px;
  }

  .expanded-district {
    background-color: #F1F9FE;
    padding-top: 10px;
  }
  
  .expanded-region {
    background-color: #E1F0FA;
    padding-top: 10px;
    margin-left: 0 !important;
  }

  .unset-expanded-region,
  .expanded-district:first-of-type {
    padding-top: 0 !important;
    margin-left: -1.6rem !important;
    @include respond("phone") {
      margin-left: -1.4rem !important;
    }
  }
}
