@import "./src/sass/index.scss";

.sales-detail-tab {
  overflow: hidden;

  .bold {
    font-weight: bold;
  }

  height: 100%;

  .summary-row {
    display: flex;
    flex-direction: row;
    height: 300px;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;

    @include respond("phone") {
      height: 150px;
    }

    .summary-row-header-container {
      display: flex;
      flex-basis: 4%;
      flex-direction: column;
      align-items: center;
      overflow: visible;

      .total-sales-label {
        .label {
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          font-size: 12px;
          text-align: center;
          letter-spacing: 0.02em;
        }
      }

      .total-sales-value {
        .value {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: rgba(35, 35, 35, 1);
          letter-spacing: 0.02em;
        }
      }
    }

    .summary-row-meter-container {
      display: flex;
      justify-content: center;
      flex-basis: 100%;
      overflow: hidden;
    }

    .meter-value-text {
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      line-height: 2rem;
      text-align: center;
      color: rgba(0, 0, 0, 1);
      font-size: 20px;
      font-weight: 900;
      padding-top: 5.5rem;
    }

    .text {
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      font-size: 20px;
      text-align: center;
      letter-spacing: 0.01em;
    }
  }

  .sales-detail-table-container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    height: 100%;
    overflow: auto;
    text-align: center;

    .header-text {
      font-weight: 400;
      font-size: 1.7rem;
      color: rgba(41, 41, 41, 1);
    }

    .data-text {
      color: rgba(32, 32, 32, 1);

      &.first-column {
        text-align: start;
        font-weight: 850;
      }

      &.first-row {
        font-weight: 950;

        @include respond("tab-port") {
          font-size: 2rem;
        }

        @include respond("phone") {
          font-size: 1.2rem;
        }

        @include respond("desktop") {
          font-size: 5rem;
        }
      }
    }

    .value-row {
      @include respond("tab-port") {
        font-size: 2rem;
      }

      @include respond("phone") {
        font-size: 1.2rem;
      }

      @include respond("desktop") {
        font-size: 5rem;
      }

      font-weight: 500;
    }

    .border-row {
      width: 100%;
      height: 1px;
      margin-top: 5px;
      background-color: rgb(85 85 85 / 48%);
    }

    .table-content {
      overflow: auto;
      height: calc(100% - 44px);

      @media only screen and (max-width: 280px) {
        height: calc(100% - 70px);
      }

      .table-subrow-container {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }
  }

  .sales-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }

  .second-header {
    text-align: right;
    padding-right: 10px;
  }

  .third-header {
    text-align: right;
    padding-right: 15px;

    @media only screen and (min-width: 922px) {
      padding-right: 40px;
    }
  }

  .first {
    text-align: left;
    padding-left: 5px;
  }

  .second {
    text-align: right;
    padding-right: 5px;
  }

  .third {
    text-align: right;
    padding-right: 5px;
  }

  .py-20 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}