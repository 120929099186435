@import "./src/sass/index.scss";

.bottomPane {
	height: 100%;
	.innerBottomPane {
		display: flex;
		flex-direction: column;
		height: 100%;

		.titleBottomPane {
			height: 10%;
			.title-row-bottom {
				font-weight: bolder;
				color: rgba(0, 0, 0, 1);
				letter-spacing: 0.025em;
				margin-top: 10px;
				@include respond("tab-port") {
					font-size: 2.5rem;
				}
				@include respond("phone") {
					font-size: 1.5rem;
				}
				@include respond("desktop") {
					font-size: 5rem;
				}
			}
		}

		.contentBottomPane {
			margin: 5px;
			justify-content: center;
			canvas {
				@media screen {
					@media screen and (max-width: 767px) {
						min-height: 150px;
					}
				}
			}
		}
	}
}

.sales-trend-titles {
	font-family: Franklin Gothic Pro !important;
	font-size: 2rem !important;
	color: rgba(32, 32, 32, 1) !important;
	text-align: center !important;
	margin-left: 16px !important;
	font-weight: bolder !important;
}

.sales-trend-container {
	padding: 2px;
	height: 100%;
	width: 100%;
	margin-top: 10px;
    margin-bottom: 10px;
	overflow: auto;
	.sales-detail-loader {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;
		width: 100%;
	}
}

.splitScreen {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: space-between;
}

.middlePane {
	height: 100%;
	margin-top: 5px;
	.innerMiddlePane {
		display: flex;
		flex-direction: column;
		height: 100%;
		.titleMiddlePane {
			height: 19%;
			.title-row-middle {
				font-weight: bolder;
				color: rgba(0, 0, 0, 1);
				letter-spacing: 0.025em;
				@include respond("tab-port") {
					font-size: 2.5rem;
				}
				@include respond("phone") {
					font-size: 1.5rem;
				}
				@include respond("desktop") {
					font-size: 5rem;
				}
			}
		}

		.contentMiddlePane {
			margin: 5px;
			justify-content: center;
		}
	}
}

.topPane {
	height: 100%;
	width: 98%;
	.innerTopPane {
		display: flex;
		flex-direction: column;
		height: 100%;
		.titleTopPane {
			height: 19%;
			.title-row-top {
				font-weight: bolder;
				color: rgba(0, 0, 0, 1);
				letter-spacing: 0.025em;
				@include respond("tab-port") {
					font-size: 2.5rem;
				}
				@include respond("phone") {
					font-size: 1.5rem;
				}
				@include respond("desktop") {
					font-size: 5rem;
				}
			}
		}
		.contentTopPane {
			margin: 5px;
			justify-content: center;
		}
	}
}

.divider {
	border: none;
	height: 2px !important;
	margin-top: 10px !important;
	margin-bottom: 4px !important;
	flex-shrink: 0;
	background-color: rgba(0, 0, 0, 0.32) !important;
}
