.outer-class {
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 10px;

  .primary-text {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .secondary-text {
    font-size: 1.6rem;
    font-weight: 800;
    color: #202020;
  }
}
