@import "./src/sass/index.scss";

.user-impersonation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
    height: 100%;
    .data-row {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .location-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .title {
        font-weight: 900;
        font-family: "Franklin Gothic Pro";
        color: #353535;
        font-size: 40px;
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .btn {
        height: 35px;
        width: 100px;
    }
    .search {
        flex-grow: 1;
    }
    .search-icon {
        color: #0662ed;
    }

    .location-list-container {
        display: flex;
        width: 90%;
        flex-direction: row;
        flex-grow: 1;
        padding-bottom: 60px;

        .location-list-column {
            width: 70%;
            align-items: center;
            flex-grow: 1;
            margin-top: 5px;
            max-height: 55vh;
            @include respond("phone") {
                max-height: 55vh;
            }
        }
    }
    .location-list-blank {
        max-height: 55vh;
    }
    .location-list {
        max-height: 45vh;
        overflow-y: scroll;
        width: 100%;
        @include respond("phone") {
            max-height: 55vh;
        }
        border: 1px #dcdcdc;
        border-width: 2px;
        border-style: inset;
        max-width: "55vw";
        .location-row {
            margin: 0px;
            border: 1px solid #a2bbe0;
        }
    }
    .btn-row {
        width: 100%;
        flex-direction: row;
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        bottom: 0;
        margin-bottom: 2rem;
        position: absolute;
    }
    .dropdown-container {
        margin-top: 5px;
        margin-bottom: 5px;
        height: 4rem;
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        justify-content: flex-start;
        width: 90%;
        .role_title_container {
            width: 20%;
            .role_title {
                font-weight: bold;
                font-size: 1.6rem;
                font-family: Franklin Gothic Pro;
                justify-content: flex-end;
                align-items: center;
                float: right;
                padding: 5px;
                margin: 5px;
            }
        }

        .impersonate-dropdown-column {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            flex-basis: 60%;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            .impersonate-dropdown-row {
                width: 100%;
                font-weight: 600;
                font-size: 1.8rem;
                @include respond("phone") {
                    font-size: 1.4rem;
                }
                .css-1s2u09g-control,
                .css-1pahdxg-control {
                    background: #ffffff !important;
                    .css-319lph-ValueContainer {
                        .css-qc6sy-singleValue {
                            color: #353535 !important;
                        }
                    }
                }
            }
        }
    }
    .searchBox-container {
        margin-bottom: 5px;
        height: 4rem;
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        justify-content: flex-start;
        width: 90%;
        .location_title_container {
            width: 20%;
            .location_title {
                font-weight: bold;
                font-size: 1.6rem;
                font-family: Franklin Gothic Pro;
                justify-content: flex-end;
                align-items: center;
                float: right;
                padding: 5px;
                margin: 5px;
            }
        }

        .locationSearch-column {
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
            flex-basis: 60%;
            align-items: center;
            flex-grow: 1;
        }
    }
}
