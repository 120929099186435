@import "./src/sass/index.scss";
.detail-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 94%;
  margin-left: 1rem;
  margin-right: 1rem;
  .dropdown-container {
    margin-top: 6px;
    margin-bottom: 7px;
    justify-content: flex-end;
    display: inline-block;
    width: 61%;
    align-self: center;
    .dropdown-column {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-basis: 60%;
      justify-content: center;
      align-items: center;
      margin: 5px 0px;
      .dropdown-row {
        width: 100%;
        font-weight: 100;
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        @include respond("desktop") {
          font-size: 2rem;
        }
      }
      .dropdown-row > div {
        border-color: #353535 !important;
        border-radius: 0px !important;
        min-height: 33px !important;
        min-width: max-content;
        .css-qc6sy-singleValue {
          font-size: 1.8rem !important;
          padding-top: 1px;
          font-weight: 100;
        }
        .css-1okebmr-indicatorSeparator {
          display: none !important;
        }
        .css-1gtu0rj-indicatorContainer,
        .css-tlfecz-indicatorContainer {
          padding: 0 !important;
        }
        svg {
          display: inline-block;
          fill: #0066ff !important;
          width: 4rem !important;
          height: 3rem !important;
        }
      }
    }
    .adjustment-level-dropdown {
      margin-top: 15px;
    }
  }
  .total-adj-table-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    text-align: center;
    height: calc(100% - 4rem);
    .total-adj-border-row {
      width: 100%;
      height: 1px;
      margin-top: 7px;
      background-color: #bfb2b2;
    }
    .total-adj-top-border-row {
      background-color: #0066ff;
    }
    .total-adj-sku-border-row {
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #bfb2b2;
    }
    .total-adj-title {
      padding-right: 5px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      flex-shrink: 0;
      font-weight: 850;
      font-size: 1.8rem;
      @include respond("tab-port") {
        font-size: 2rem;
      }
      @include respond("phone") {
        font-size: 1.6rem;
      }
    }
    .total-adj-row-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .total-adj-Subtitle {
        padding-right: 5px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-shrink: 0;
        font-size: 1.7rem;
        font-weight: bolder;
        text-align: start;
        width: 100%;
        color: #202020;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
      }
    }

    .total-adj-sales-container {
      flex-shrink: 0;
      .total-adj-sales-value {
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        font-weight: bolder;
      }
      .total-adj-sales-label {
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        color: #555555;
      }
    }

    .total-adj-sku-container {
      flex-shrink: 0;
      text-align: center;
      .total-adj-sku-value {
        font-size: 1.6rem;
        @include respond("tab-port") {
          font-size: 1.7rem;
        }
        @include respond("phone") {
          font-size: 1.3rem;
        }
        font-weight: bolder;
      }
      .total-adj-sku-label {
        font-size: 1.5rem;
        @include respond("tab-port") {
          font-size: 1.6rem;
        }
        @include respond("phone") {
          font-size: 1.2rem;
        }
        color: #555555;
      }
    }

    .total-adj-table-content {
      .sort-container {
        position: relative;
        .sort-box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 1rem;
          margin-top: 0.5rem;
          margin-bottom: -0.9rem;
          font-weight: 850;
          font-size: 2rem;
          @include respond("phone") {
            font-size: 1.6rem;
          }
        }
        .sort-menu-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          right: 0;
          margin-right: 1.5rem;
          border: 1px solid rgba(85, 85, 85, 1);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 5px;
          background-color: white;
          .menu-item {
            display: flex;
            justify-content: space-between;
            min-width: 20rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(85, 85, 85, 1);
            }
            &.ascending-menu-item {
              border-top: 2px solid rgba(85, 85, 85, 1);
            }
            font-weight: 850;
            font-size: 1.8rem;
            @include respond("phone") {
              font-size: 1.6rem;
            }
            &.apply-button {
              font-size: 1.7rem;
              justify-content: center;
            }
            &.sort-by-header {
              font-size: 1.6rem;
              justify-content: center;
              color: #818080;
            }
          }
        }
      }
    }
    .sku-table-container {
      height: calc(100% - 50px);
      @include respond("phone") {
        height: calc(100% - 60px);
      }
      text-align: center;
      overflow: auto;
    }
    .class-sku-table-container {
      height: 100%;
      padding-bottom: 160px;
      @include respond("tab-port") {
        padding-bottom: 145px;
      }
      @include respond("phone") {
        padding-bottom: 130px;
      }
    }
  }
  .total-adj-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    height: 50%;
  }
}
.noData-card {
  display: flex;
  flex-direction: column;
  height: auto;
  background: white;
  text-align: center;

  .no-data-row {
    .label {
      justify-content: center;
      font-weight: 900;
      font-size: 2rem;
      color: #010101;
      letter-spacing: 0.02em;
      padding-top: 4rem;
    }
  }
}

.generic-sort-container-expanded {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  margin-right: 1rem;
  border: 1px solid rgba(85, 85, 85, 1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  background-color: white;

  .menu-item {
    display: flex;
    justify-content: space-between;
    min-width: 20rem;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(85, 85, 85, 1);
    }

    &.ascending-menu-item {
      border-top: 2px solid rgba(85, 85, 85, 1);
    }

    font-weight: 850;
    font-size: 1.8rem;

    @include respond("phone") {
      font-size: 1.6rem;
    }

    &.apply-button {
      font-size: 1.7rem;
      justify-content: center;
    }

    &.sort-by-header {
      font-size: 1.6rem;
      justify-content: center;
      color: #818080;
    }
  }
}

.total-adj-detail-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 94%;
  margin-left: 1rem;
  margin-right: 1rem;
  .total-adj-dropdown-container {
    margin-top: 6px;
    margin-bottom: 7px;
    justify-content: flex-end;
    display: inline-block;
    width: 61%;
    align-self: center;
    .total-adj-dropdown-column {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-basis: 60%;
      justify-content: center;
      align-items: center;
      margin: 5px 0px;
      .total-adj-dropdown-row {
        width: 100%;
        font-weight: 100;
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        @include respond("desktop") {
          font-size: 2rem;
        }
      }
      .total-adj-dropdown-row > div{
        border-color: #353535 !important;
        border-radius: 0px !important;
        min-height: 33px !important;
         .css-qc6sy-singleValue{
          font-size: 1.8rem !important;
          padding-top: 1px;
          font-weight: 100;
        }
        .css-1okebmr-indicatorSeparator{
          display: none !important;
        }
        .css-1gtu0rj-indicatorContainer,
        .css-tlfecz-indicatorContainer{
          padding: 0 !important;
        }
        svg {
          display: inline-block;
          fill: #0066ff !important;
          width: 4rem !important;
          height: 3rem !important;
        }
      }
    }
    .total-adj-class-level-dropdown {
      margin-top: 15px;
    }
  }
  .total-adj-table-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    text-align: center;
    height: calc(100% - 4rem);
    .total-adj-border-row {
      width: 100%;
      height: 1px;
      margin-top: 7px;
      background-color: #bfb2b2;
    }
    .total-adj-top-border-row {
      background-color: #0066ff;
    }
    .total-adj-sku-border-row {
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #bfb2b2;
    }
    .total-adj-title {
      padding-right: 5px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      flex-shrink: 0;
      font-weight: 850;
      font-size: 1.8rem;
      @include respond("tab-port") {
        font-size: 2rem;
      }
      @include respond("phone") {
        font-size: 1.6rem;
      }
    }
    .total-adj-row-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .total-adj-Subtitle {
        padding-right: 5px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-shrink: 0;
        font-size: 1.7rem;
        font-weight: bolder;
        text-align: start;
        width: 100%;
        color: #202020;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
      }
    }

    .total-adj-sales-container {
      flex-shrink: 0;
      .total-adj-sales-value {
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        font-weight: bolder;
      }
      .total-adj-sales-label {
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        color: #555555;
      }
    }

    .total-adj-sku-container {
      flex-shrink: 0;
      text-align: center;
      .total-adj-sku-value {
        font-size: 1.6rem;
        @include respond("tab-port") {
          font-size: 1.7rem;
        }
        @include respond("phone") {
          font-size: 1.3rem;
        }
        font-weight: bolder;
      }
      .total-adj-sku-label {
        font-size: 1.5rem;
        @include respond("tab-port") {
          font-size: 1.6rem;
        }
        @include respond("phone") {
          font-size: 1.2rem;
        }
        color: #555555;
      }
    }

    .total-adj-table-content {
      .sort-container {
        position: relative;
        .sort-box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 1rem;
          margin-top: 0.5rem;
          margin-bottom: -0.9rem;
          font-weight: 850;
          font-size: 2rem;
          @include respond("phone") {
            font-size: 1.6rem;
          }
        }
        .sort-menu-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          right: 0;
          margin-right: 1.5rem;
          border: 1px solid rgba(85, 85, 85, 1);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 5px;
          background-color: white;
          .menu-item {
            display: flex;
            justify-content: space-between;
            min-width: 20rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(85, 85, 85, 1);
            }
            &.ascending-menu-item {
              border-top: 2px solid rgba(85, 85, 85, 1);
            }
            font-weight: 850;
            font-size: 1.8rem;
            @include respond("phone") {
              font-size: 1.6rem;
            }
            &.apply-button {
              font-size: 1.7rem;
              justify-content: center;
            }
            &.sort-by-header {
              font-size: 1.6rem;
              justify-content: center;
              color: #818080;
            }
          }
        }
      }
    }
    .sku-table-container {
      height: calc(100% - 50px);
      @include respond("phone") {
        height: calc(100% - 60px);
      }
      text-align: center;
      overflow: auto;
    }
    .class-sku-table-container {
      height: 100%;
      padding-bottom: 160px;
      @include respond("tab-port") {
        padding-bottom: 145px;
      }
      @include respond("phone") {
        padding-bottom: 130px;
      }
    }
  }
  .total-adj-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    height: 50%;
  }
}
.total-adj-noData-card {
  display: flex;
  flex-direction: column;
  height: 10rem;
  background: white;
  text-align: center;

  .total-adj-no-data-row {
    .label {
      justify-content: center;
      font-weight: 900;
      font-size: 2rem;
      color: #010101;
      letter-spacing: 0.02em;
      padding-top: 4rem;
    }
  }
}

.generic-sort-container-expanded {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  margin-right: 1rem;
  border: 1px solid rgba(85, 85, 85, 1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  background-color: white;

  .menu-item {
    display: flex;
    justify-content: space-between;
    min-width: 20rem;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(85, 85, 85, 1);
    }

    &.ascending-menu-item {
      border-top: 2px solid rgba(85, 85, 85, 1);
    }

    font-weight: 850;
    font-size: 1.8rem;

    @include respond("phone") {
      font-size: 1.6rem;
    }

    &.apply-button {
      font-size: 1.7rem;
      justify-content: center;
    }

    &.sort-by-header {
      font-size: 1.6rem;
      justify-content: center;
      color: #818080;
    }
  }
}

.total-adj-detail-loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.damages-detail-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 94%;
  margin-left: 1rem;
  margin-right: 1rem;
  .damages-dropdown-container {
    margin-top: 6px;
    margin-bottom: 7px;
    justify-content: flex-end;
    display: inline-block;
    width: 61%;
    align-self: center;
    .damages-dropdown-column {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-basis: 60%;
      justify-content: center;
      align-items: center;
      margin: 5px 0px;
      .damages-dropdown-row {
        width: 100%;
        font-weight: 100;
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        @include respond("desktop") {
          font-size: 2rem;
        }
      }
      .damages-dropdown-row > div{
        border-color: #353535 !important;
        border-radius: 0px !important;
        min-height: 33px !important;
         .css-qc6sy-singleValue{
          font-size: 1.8rem !important;
          padding-top: 1px;
          font-weight: 100;
        }
        .css-1okebmr-indicatorSeparator{
          display: none !important;
        }
        .css-1gtu0rj-indicatorContainer,
        .css-tlfecz-indicatorContainer{
          padding: 0 !important;
        }
        svg {
          display: inline-block;
          fill: #0066ff !important;
          width: 4rem !important;
          height: 3rem !important;
        }
      }
    }
    .damages-class-level-dropdown {
      margin-top: 15px;
    }
  }
  .damages-table-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    text-align: center;
    height: calc(100% - 5.8rem);
    .damages-border-row {
      width: 100%;
      height: 1px;
      margin-top: 7px;
      background-color: #bfb2b2;
    }
    .damages-top-border-row {
      background-color: #0066ff;
    }
    .damages-sku-border-row {
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #bfb2b2;
    }
    .damages-title {
      padding-right: 5px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      flex-shrink: 0;
      font-weight: 850;
      font-size: 1.8rem;
      @include respond("tab-port") {
        font-size: 2rem;
      }
      @include respond("phone") {
        font-size: 1.6rem;
      }
    }
    .damages-row-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .damages-Subtitle {
        padding-right: 5px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-shrink: 0;
        font-size: 1.7rem;
        font-weight: bolder;
        text-align: start;
        width: 100%;
        color: #202020;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
      }
    }

    .damages-sales-container {
      flex-shrink: 0;
      .damages-sales-value {
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        font-weight: bolder;
      }
      .damages-sales-label {
        font-size: 1.8rem;
        @include respond("tab-port") {
          font-size: 1.8rem;
        }
        @include respond("phone") {
          font-size: 1.4rem;
        }
        color: #555555;
      }
    }

    .damages-sku-container {
      flex-shrink: 0;
      text-align: center;
      .damages-sku-value {
        font-size: 1.6rem;
        @include respond("tab-port") {
          font-size: 1.7rem;
        }
        @include respond("phone") {
          font-size: 1.3rem;
        }
        font-weight: bolder;
      }
      .damages-sku-label {
        font-size: 1.5rem;
        @include respond("tab-port") {
          font-size: 1.6rem;
        }
        @include respond("phone") {
          font-size: 1.2rem;
        }
        color: #555555;
      }
    }

    .damages-table-content {
      .driver-title{
        color: #202020;
      }
      .sort-container {
        position: relative;
        .sort-box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 1rem;
          margin-top: 0.5rem;
          margin-bottom: -0.9rem;
          font-weight: 850;
          font-size: 2rem;
          @include respond("phone") {
            font-size: 1.6rem;
          }
        }
        .sort-menu-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          right: 0;
          margin-right: 1.5rem;
          border: 1px solid rgba(85, 85, 85, 1);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 5px;
          background-color: white;
          .menu-item {
            display: flex;
            justify-content: space-between;
            min-width: 20rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(85, 85, 85, 1);
            }
            &.ascending-menu-item {
              border-top: 2px solid rgba(85, 85, 85, 1);
            }
            font-weight: 850;
            font-size: 1.8rem;
            @include respond("phone") {
              font-size: 1.6rem;
            }
            &.apply-button {
              font-size: 1.7rem;
              justify-content: center;
            }
            &.sort-by-header {
              font-size: 1.6rem;
              justify-content: center;
              color: #818080;
            }
          }
        }
      }
    }
    .sku-table-container {
      height: calc(100% - 50px);
      @include respond("phone") {
        height: calc(100% - 60px);
      }
      text-align: center;
      overflow: auto;
    }
    .class-sku-table-container {
      height: 100%;
      padding-bottom: 160px;
      @include respond("tab-port") {
        padding-bottom: 145px;
      }
      @include respond("phone") {
        padding-bottom: 130px;
      }
    }
  }
  .damages-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    height: 50%;
  }
}
.damages-noData-card {
  display: flex;
  flex-direction: column;
  height: 10rem;
  background: white;
  text-align: center;

  .damages-no-data-row {
    .label {
      justify-content: center;
      font-weight: 900;
      font-size: 2rem;
      color: #010101;
      letter-spacing: 0.02em;
      padding-top: 4rem;
    }
  }
}

.generic-sort-container-expanded {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  margin-right: 1rem;
  border: 1px solid rgba(85, 85, 85, 1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  background-color: white;

  .menu-item {
    display: flex;
    justify-content: space-between;
    min-width: 20rem;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(85, 85, 85, 1);
    }

    &.ascending-menu-item {
      border-top: 2px solid rgba(85, 85, 85, 1);
    }

    font-weight: 850;
    font-size: 1.8rem;

    @include respond("phone") {
      font-size: 1.6rem;
    }

    &.apply-button {
      font-size: 1.7rem;
      justify-content: center;
    }

    &.sort-by-header {
      font-size: 1.6rem;
      justify-content: center;
      color: #818080;
    }
  }
}

.damages-detail-loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.IC-adjustment-longsku-data {
  height: calc(100% - 20rem);
  padding-bottom: 0px;
  @include respond("tab-port") {
    height: calc(100% - 18rem);
  }
  @media only screen and (min-width: 911px) and (max-width: 920px){
    height: calc(100% - 16rem);
  }
  @include respond("phone") {
    height: calc(100% - 17.5rem);
  }
  @media only screen and (max-width: 396px), (min-width: 512px) and (max-width: 540px){
    height: calc(100% - 18.5rem);
  }
}

.IC-adjustment-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .IC-adjustment-Subtitle {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex-shrink: 0;
    font-size: 1.7rem;
    font-weight: bolder;
    text-align: start;
    width: 100%;
    color: #202020;
    @include respond("tab-port") {
      font-size: 1.8rem;
    }
    @include respond("phone") {
      font-size: 1.4rem;
    }
  }
}

.IC-adjustment-sales-container {
  flex-shrink: 0;
  .IC-adjustment-sales-value {
    font-size: 1.8rem;
    @include respond("tab-port") {
      font-size: 1.8rem;
    }
    @include respond("phone") {
      font-size: 1.4rem;
    }
    font-weight: bolder;
  }
  .IC-adjustment-sales-label {
    font-size: 1.8rem;
    @include respond("tab-port") {
      font-size: 1.8rem;
    }
    @include respond("phone") {
      font-size: 1.4rem;
    }
    color: #555555;
  }
}

.IC-adjustment-sku-container {
  flex-shrink: 0;
  text-align: center;
  .IC-adjustment-sku-value {
    font-size: 1.6rem;
    @include respond("tab-port") {
      font-size: 1.7rem;
    }
    @include respond("phone") {
      font-size: 1.3rem;
    }
    font-weight: bolder;
  }
  .IC-adjustment-sku-label {
    font-size: 1.5rem;
    @include respond("tab-port") {
      font-size: 1.6rem;
    }
    @include respond("phone") {
      font-size: 1.2rem;
    }
    color: #555555;
  }
}